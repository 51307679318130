var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-head-nav"},[_c('div',{staticClass:"nav-part nav-part-left"},[(!_vm.isCompanyOwner)?_c('div',{staticClass:"action"},[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.setFavFromCompanyHeadNav($event)}}},[(_vm.isFavoriteCompany)?_c('iconFavorite',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('button.rm_favorites'),
            classes: ['vue-tooltip-dark'],
            placement: 'bottom',
            delay: 0,
          }),expression:"{\n            content: $t('button.rm_favorites'),\n            classes: ['vue-tooltip-dark'],\n            placement: 'bottom',\n            delay: 0,\n          }"}]}):_c('iconUnFavorite',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('button.favorites'),
            classes: ['vue-tooltip-dark'],
            placement: 'bottom',
            delay: 0,
          }),expression:"{\n            content: $t('button.favorites'),\n            classes: ['vue-tooltip-dark'],\n            placement: 'bottom',\n            delay: 0,\n          }"}]})],1)]):_vm._e(),_c('div',{staticClass:"action"},[_c('iconShare',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('button.share'),
          classes: ['vue-tooltip-dark'],
          placement: 'bottom',
          delay: 0,
        }),expression:"{\n          content: $t('button.share'),\n          classes: ['vue-tooltip-dark'],\n          placement: 'bottom',\n          delay: 0,\n        }"}],nativeOn:{"click":function($event){$event.stopPropagation();return _vm.shareCompany($event)}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isContactTeamMember && _vm.event && _vm.event.is_enabled),expression:"isContactTeamMember && event && event.is_enabled"},{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('meetingRooms.StartBroadcast'),
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: $t('meetingRooms.StartBroadcast'),\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"action action-start-broadcast",on:{"click":_vm.onStartBroadcastClick}},[_c('icon-broadcast',{staticClass:"icon"}),(_vm.isPromoBroadcastMenuVisible)?_c('promo-broadcast-menu',{attrs:{"type":_vm.BroadcastType.PROMO_CONTACT}}):_vm._e()],1),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('meetingRooms.toggleFullScreen'),
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: $t('meetingRooms.toggleFullScreen'),\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"action action-toggle-fullscreen",on:{"click":_vm.onToggleFullScreenClick}},[_c('span',{staticClass:"icon"})]),_c('div',{staticClass:"company-title-top"},[_vm._v(" "+_vm._s(_vm.company.title)+" ")])]),_c('div',{staticClass:"nav-part nav-part-right"},[(_vm.mediaItems.length)?_c('div',{staticClass:"pagination-wrapper"},[_c('button',{staticClass:"pagination-arrow pagination-arrow-left",on:{"click":function($event){return _vm.rewind()}}},[_c('icon-chevron-left',{attrs:{"disabled":_vm.activeSlideIndex < 1}})],1),_c('div',{staticClass:"pagination-overflow"},[_c('div',{ref:"company-media-pagination",staticClass:"pagination"},[_vm._l((_vm.mediaItems),function(item){return [_c('div',{staticClass:"item",class:{'active': item.isActive},on:{"click":function($event){_vm.activateMediaItem(_vm.mediaItems.indexOf(item))}}},[_vm._v(" "+_vm._s(_vm.mediaItems.indexOf(item) + 1)+" ")])]})],2)]),_c('button',{staticClass:"pagination-arrow pagination-arrow-right",on:{"click":function($event){return _vm.forward()}}},[_c('icon-chevron-right',{attrs:{"disabled":_vm.activeSlideIndex === _vm.mediaItems.length - 1}})],1)]):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('button.close'),
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: $t('button.close'),\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"action action-close",on:{"click":_vm.close}})])])}
var staticRenderFns = []

export { render, staticRenderFns }