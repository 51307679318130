


import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import iconUnFavorite from '@/_modules/icons/components/icon-ew-unfavorite.vue';
import iconFavorite from '@/_modules/icons/components/icon-ew-favorite.vue';
import iconShare from '@/_modules/icons/components/icon-ew-share.vue';
import IconChevronLeft from '@/_modules/icons/components/single-row-calendar/chevron-left.vue';
import IconChevronRight from '@/_modules/icons/components/single-row-calendar/chevron-right.vue';
import IconBroadcast from '@/_modules/icons/components/icon-broadcast-no-circle.vue';
import PromoBroadcastMenu from '@/_modules/promo/components/promo-broadcast-menu/promo-broadcast-menu.vue';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TContact } from '@/_types/contact.type';
import { TOpenEwSharerPayload } from '@/_store/ew-sharer.store';
import { TMediaItem } from '@/_modules/events/types/media-item.type';
import { TEvent } from '@/_types/event.type';
import EventHelper from '@/_helpers/event.helper';
import { TimeStatus } from '@/_types/time-status.enum';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';

@Component({
  components: {
    iconUnFavorite,
    iconFavorite,
    iconShare,
    IconChevronLeft,
    IconChevronRight,
    IconBroadcast,
    PromoBroadcastMenu,
  }
})
export default class CompanyHead extends Vue {

  @Ref('company-media-pagination') companyMediaPagination: HTMLDivElement;
  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;

  @Getter('_eventStore/event') event: TEvent;
  @Getter('promoStore/promoPageByExternalId') promoPageByExternalId: (id: string) => TPromoPage;

  @Prop({ type: Object, default: {} }) readonly company: TPromoPage;
  @Prop({ type: Object, default: {} }) readonly contact: TContact;

  public isFavoriteCompany: boolean = false;
  public activeSlideIndex: number = 0;
  public BroadcastType: typeof BroadcastType = BroadcastType;
  public isPromoBroadcastMenuVisible: boolean = false;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get isContactTeamMember(): boolean {
    if (!this.contact
      || !this.company
      || !this.company.attached_contacts
      || this.company.attached_contacts.length < 1
    ) {
      return false;
    }
    const contactId = this.contact.id;
    const attachedContacts = this.company.attached_contacts;
    return !!(attachedContacts.find(c => c.contact.id === contactId));
  }

  public get isCompanyOwner(): boolean {
    if (!this.company || !this.contact || !this.company.attached_contacts) {
      return false;
    }
    return !!this.company.attached_contacts.find(attachedContact => {
      return attachedContact.role === 'owner' && attachedContact.contact.id === this.contact.id;
    });
  }

  public get externalId(): string {
    return this.company && this.company.external_id;
  }

  public get companyFromOldStore(): TPromoPage {
    return this.promoPageByExternalId(this.externalId);
  }

  public get mediaItems(): TMediaItem[] {
    return this.companyFromOldStore && this.companyFromOldStore.companyMediaItems;
  }

  public mounted(): void {
    this.subscribeToPageEvents();
  }

  public beforeDestroy(): void {
    this.unsubscribeFromPageEvents();
  }

  public setFavFromCompanyHeadNav(): void {
    this.isFavoriteCompany = !this.isFavoriteCompany;
    const payload: any = {
      event_id: this.eventId,
      external_id: this.externalId
    };

    if (!this.isFavoriteCompany) {
      this.$store.dispatch('promoPageStore/removeFavorite', payload);
    } else {
      this.$store.dispatch('promoPageStore/addFavorite', payload);
    }
  }

  public shareCompany(event: PointerEvent): void {
    this.isPromoBroadcastMenuVisible = false;

    const shareUrl: string = window.location.origin + this.$router.resolve({
      name: 'promo-page-events-company',
      params: {
        eventId: '' + this.eventId,
        external_id: this.externalId,
      }
    }).href;

    this.openSharer({
      eventTarget: (event.target as Element),
      url: shareUrl,
    });
  }

  public activateMediaItem(key: number): void {
    this.activeSlideIndex = key;
    this.$emit('activatedSlideIndex', key);
  }

  public rewind(): void {
    if (this.activeSlideIndex > 0) {
      --this.activeSlideIndex;
      this.$emit('activatedSlideIndex', this.activeSlideIndex);
      if ((this.activeSlideIndex % 5) === 4) {
        this.companyMediaPagination.style.marginLeft = (+this.companyMediaPagination.style.marginLeft.split('p')[0] + (18 * 5)) + 'px';
      }
    }
  }

  public forward(): void {
    if (this.activeSlideIndex < this.mediaItems.length - 1) {
      ++this.activeSlideIndex;
      this.$emit('activatedSlideIndex', this.activeSlideIndex);
      if (this.activeSlideIndex % 5 === 0) {
        this.companyMediaPagination.style.marginLeft = -this.activeSlideIndex * 18 + 'px';
      }
    }
  }

  public close(): void {
    this.$emit('companyCardClosed', { externalId: this.externalId }); // AW-3130
    this.$router.push({
      name: 'promo-page-events-companies',
    }).catch(/* ignore */);
  }

  public onStartBroadcastClick(event: PointerEvent): void {
    event.stopPropagation();

    this.closeSharer();

    this.isPromoBroadcastMenuVisible = !this.isPromoBroadcastMenuVisible;

    const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
    if (eventTimeStatus === TimeStatus.PAST || eventTimeStatus === TimeStatus.FUTURE) {
      this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
    }
  }

  public subscribeToPageEvents(): void {
    document.addEventListener('click', this.onDocumentClick);
  }

  public unsubscribeFromPageEvents(): void {
    document.removeEventListener('click', this.onDocumentClick);
  }

  public onDocumentClick(): void {
    this.isPromoBroadcastMenuVisible = false;
  }

  public onToggleFullScreenClick(): void {
    this.$emit('enable-fullscreen-mode');
  }
}
